<template>
  <div>
    <c-card title="배관 및 개스킷 업로드 (동일한 분류코드/유체의 명칭 또는 구분 데이터는 자료가 수정됩니다.)" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'piping-gaskets-excel-upload',
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['pipeTypeCd','pipeName'], // 데이터들의 키 속성값
        taskClassCd: 'PSI_PIPE_GASKET_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:110px',
            type: 'plant',
            sortable: false,
          },
          {
            name: 'pipeTypeCd',
            label: '분류코드',
            align: 'center',
            type: 'link',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'pipeName',
            label: '유체의명칭 또는 구분',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'designPress',
            label: '설계압력(MPa)',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'designTemp',
            label: '설계온도(℃)',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'pipeMaterial',
            label: '배관재질',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'gasketMaterial',
            label: '개스킷 재질 및 형태',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'noneDestructPercent',
            label: '비파괴검사율(%)',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'afterHeatTreatFlag',
            label: '후열처리여부',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'remarks',
            label: '비고',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'deptName',
            label: '관리부서명',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:120px',
            type: 'dept',
            sortable: false,
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다. 데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', 
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '에러가 있는 데이터가 존재합니다. 데이터 확인 후 적용하시길 바랍니다.', 
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '해당 엑셀데이터를 업로드하면, 동일한 분류코드와 유체명 데이터가 수정됩니다. 그대로 적용하시겠습니까?',
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
